import {extend} from 'lodash';
import {EnvironmentBaseService} from "src/environments/environment-base.service";


export const environment = extend(EnvironmentBaseService, {
  siteenv: 'dev',
  listDataS3Bucket: 'skf-list-data-dev',
  fileUploadS3Bucket: 'skf-amer-bi-dev',
  APIEndpoint: 'https://devappsapi.skfilluminate.net',
  APIEndPointImportAllocation:"https://3i86z7zm8i.execute-api.us-east-1.amazonaws.com/dev",
  APIEndpointMariaDB: 'https://kgdol3budc.execute-api.us-east-1.amazonaws.com/Stage',
  APISnowflakeEndpoint: 'https://50sgupi0sd.execute-api.us-east-1.amazonaws.com/Stage',
  loginurl: 'https://appsdev-skfilluminate.auth.us-east-1.amazoncognito.com/login?client_id=65eirjcev2fgcnvs8dd4dluanu&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://appsdev.skfilluminate.net',
});

